
.button {
    background: linear-gradient(to right, rgba(193,231,8,1) 0%, rgba(39,170,74,1) 100%);
    border-radius: 3rem;
    border: none;
    box-shadow: 0 5px 20px 0 rgba(39, 170, 74, 0.3);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1;
    outline: 0;
    padding: 1.5rem 3.75rem;

    &:hover {
        background: #ffffff;
        box-shadow: 0 5px 20px 0 rgba(39, 170, 74, 0.3), inset 0px 0px 0 2px #27AA4A;
        color: #293158;
    }
}
