.services {
    $left: -5vw;
    $circle_width: 39px;

    flex: 1;
    position: relative;

    @media screen and (min-width: 768px) {

        margin-left: 10vw;

        &::before {
            background: var(--service-border);
            bottom: var(--service-margin);
            content: '';
            left: $left;
            position: absolute;
            top: var(--service-margin);
            width: 3px;
        }
    }

    &__item {
        background-color:  var(--background);
        box-shadow: var(--shadow);
        margin-bottom: var(--service-margin);
        margin-top: var(--service-margin);
        padding: var(--service-padding);
        position: relative;
        text-align: center;

        @media screen and (min-width: 768px) {
            text-align: left;

            &:last-of-type .line::after,
            &:first-of-type .line::after {
                background: var(--background);
                content: '';
                height: calc(50% - #{$circle_width / 2} + var(--service-margin));
                left: $left;
                position: absolute;
                width: 3px;
            }

            &:first-of-type .line::after {
                top: calc(-1 * var(--service-margin));
            }

            &:last-of-type .line::after {
                bottom: calc(-1 * var(--service-margin));
            }

            &::before {
                background: var(--service-border);
                content: '';
                height: 2px;
                left: $left;
                position: absolute;
                top: calc(50% - 1px);
                width: 100%;
                z-index: -10;
            }

            &::after {
                background:  var(--background);
                border-radius: 50%;
                border: 10px solid var(--service-border);
                content: '';
                height: $circle_width;
                left: calc(#{$left} - #{$circle_width / 2});
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: background-color, border-color 0.2s ease;
                width: $circle_width;
            }

            &:hover::after {
                background: var(--service-border);
                border-color: #7dcc25;
            }
        }
    
    }
}
