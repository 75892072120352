:root {
    --color-dark-blue: #293158;
    --color-grey: #F5F5F8;
    --font-family-sans-serif: 'Montserrat', sans-serif;
    --text-color: var(--color-dark-blue);
    --default-spacing: 2rem;
    --service-margin: var(--default-spacing);
    --service-border: var(--color-grey);
    --page-space: var(--default-spacing);
    --spacing: 1rem;
    --link-color: var(--text-color);

    --service-padding: 1rem;
    --h1-font: 2rem;
    --h2-font: 1.6rem;
    --h3-font: 1.2rem;
    --base-align: center;
    --background: #fff;
    --header-color1: #293158;
    --header-color2: #8f9ee2;
    --navigation-color: rgb(51, 63, 115);
    --shadow-color: rgba(41, 49, 88, .25);
    --shadow: 0px 10px 30px 0px var(--shadow-color);

    @include media(">=tablet") {
        --service-margin: 4rem;
        --service-padding: 2rem;

        --h1-font: 3.25rem;
        --h2-font: 2.875rem;
        --h3-font: 1.625rem;

        --base-align: left;
    }

    @media (prefers-color-scheme: dark) {
        --background: #222;
        --text-color: #eee;
        --header-color1: #ddd;
        --header-color2: #eee;
        --shadow-color: rgba(0,0,0,0.25);
        --service-border: #1b1b1b;
    }
}

