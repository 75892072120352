@media screen and (min-width: 768px) {
    .media {
        display: flex;
        align-items: flex-start;
            
        &__figure {
            margin-right: var(--default-spacing);
        }
    
        &__body {
            flex: 1;
        }

        // Themes
        &--center {
            align-items: center;
        }
    }
}