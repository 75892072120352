.form-input {
    background-color: #fff;
    border-radius: .5rem;
    border: 1px solid rgba(41,49,88,0.1);
    box-shadow: 0px 10px 30px 0px rgba(41,49,88,0.1);
    color: #495057;
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-bottom: 35px;
    padding-top: 35px;
    text-indent: 25px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        border-color: rgb(39, 170, 74) !important; 
        box-shadow: 0px 10px 30px 0px rgba(41,49,88,0.1), 0 0 0 0.2rem rgba(39, 170, 74,0.25) !important;
    }
}