$gallery-gap: var(--default-spacing) !default;

.gallery {
    display: grid;
    gap: $gallery-gap;
    margin-bottom: $gallery-gap;
    margin-top: $gallery-gap;

    @include media(">=tablet") {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &__img {
        display: block;
        max-height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__item {
        display: block;
    }

    &--hover &__item {
        overflow: hidden;
        position: relative;

        &::before {
            background: url(../images/hoverr.png) no-repeat center center rgb(41, 49, 88, 0.81);
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            will-change: opacity;
            transition: opacity 0.2s ease;
        }

        &:hover::before {
            opacity: 1;
        }
    }

}
