.sticky-header {
    background: var(--color-dark-blue);
    box-shadow: 0rem 5px 1rem  rgba(0, 0, 0, .25);
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    z-index: 10;
}

.hamburger { pointer-events: all; cursor: pointer; } 

.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        @include media("<=tablet") {
            max-height: 50px;
        }
    }
    @include media(">tablet") {
        img {
            max-height: 70px;
            transition: max-height .15s ease-out;
        }
        &--smaller img {
            max-height: 50px;
        }
    }
}


// @include media("<=tablet") {
//     .navbar {
//         position: fixed;
//         background: var(--color-dark-blue);
//         top: 0;
//         left: 0;
//         right: 0;
//         transform: translate3d(0, -100%, 0);
//         transition: transform .15s ease-out;
//         &--mobile-active {
//             transform: translate3d(0, 0, 0);
//         }
//     }
// }
.site {
    transition: filter, opacity .1s ease-out;
}

.sticky-header {
    @include media("<=tablet") {
        transform: translate3d(0, calc(-100% + 101px), 0);
        transition: transform .3s ease-out;

        &--mobile-active {
            transform: translate3d(0, 0, 0);

            & + .site {
                filter: blur(3px);
                opacity: .2;
            }
        }
    }
}

.site-header {
    text-align: left;
    max-width: $site_width;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding: 1.5rem var(--page-space);

    @include media("<=tablet") {


        flex-direction: column;

        .navbar {
            order: -1;
            padding-bottom: 1.5rem;
        }
    }

    @include media(">tablet") {
        align-items: center;
        justify-content: space-between;
    }
}

.navbar {

    ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    a {
        text-decoration: none;
        padding: 1rem;
        font-size: 17px;
        font-weight: 700;
        color: #eee;
        word-break: keep-all;
        position:relative;
        white-space: nowrap;
        display: block;
        text-align: center;

        &:hover {
            &::before {
                position: absolute;
                content:'';
                background: #eee;
                left: 0;
                right: 0;
                height: 3px;
                bottom: 0;
            }
        }
    }
}

@include media(">tablet") {
    .navbar {
        ul {
            flex-direction: row;
            padding: 0;
            margin: 0;
            list-style: none;
            justify-content: flex-end;
        }



    }

}
