
@import "sass/functions/include-media";
@import "sass/variables";
@import "sass/themes";

@import "sass/text";

@import "sass/site.header";
@import "sass/site.footer";

@import "sass/module.button";
@import "sass/module.forms";

@import "sass/compontents/media";
@import "sass/compontents/services";
@import "sass/compontents/gallery";

@import "sass/sections/what_i_do";
@import "sass/sections/contact";
@import "sass/overrides";


html {
    box-sizing: border-box;
    max-width: $max-width;
    margin: 0 auto;
    box-shadow: var(--shadow);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}


@media screen and (min-width: 768px) {
    html {
        background-position: left top;
    }
}

body {

    background-color: var(--background);

    color: var(--text-color);
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    margin: 0;
    text-align: var(--base-align);
}

.site {
    margin: 0 auto;
    max-width: $site_width;
    position: relative;

    &__content {
        margin-left: var(--page-space);
        margin-right: var(--page-space);
    }
}

section{
    padding-top: 7rem;
}


// 1024px * 580
.intro {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @include media(">tablet") {
        padding-top: 15vw;
    }

    &__image {
        @include media(">tablet") {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            max-width: 50%;
        }
        @include media("<=tablet") {
            margin: 5rem calc(-1 * var(--page-space)) 0;
        }

        img {
            max-width: 90%;
            pointer-events: none;
            display: block;
            position: relative;
            left: -5px;
        }
    }
    
    @include media(">tablet") {
        &__content {
            width: 50%;
        }
    }
}
