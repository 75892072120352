.contact-grid {
    @include media('>=tablet') {
        margin-top: var(--spacing);
        display: grid;
        gap: 10%;
        grid-template-columns: 2fr 1fr;
        align-items: center;
    }

    img {
        max-width: 100%;

        @include media("<tablet") {
            display: none;
        }
    }


}