a {
  color: var(--link-color);
}

h1 {
    font-weight: 700;
    font-size: var(--h1-font);
    line-height: 1.3;
    background: -webkit-linear-gradient(45deg,var(--header-color1), var(--header-color2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    & + p {
        font-size: 1.2rem;
    }
}

h2 {
    font-weight: 700;
    font-size: var(--h2-font);
    position: relative;
    line-height: 1.2;

    &::after {
        position: absolute;
        content: '';
        width: 128px;
        height: 4px;
        background: linear-gradient(to right, rgba(193,231,8,1) 0%, rgba(39,170,74,1) 100%);
        left: 0%;
        bottom: -20%;

        @include media('<=tablet') {
            left: 50%;
            transform: translateX(-50%);
        }
    }

}


.text--center {
    text-align: center;
}

h2.text--center::after {
    left: 50%;
    transform: translateX(-50%);
}

h3 {
    font-size: var(--h3-font);
    font-weight: 700;
}

p { line-height: 1.8; }
