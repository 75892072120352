.site-footer {
    background: var(--color-dark-blue);
    color: #fff;
    font-weight: 400;
    padding: 2rem;
    margin-top: 3rem;

    ul {
        line-height: 1;
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-flex;
        gap: .5rem;
    }

    li {
        display: flex;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

.footer-test {
    max-width: $site_width;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 1rem;
    align-items: center;

    @include media('>=tablet') {
        grid-template-columns: repeat(2, 1fr);
    }
}

.footer-test div + div {
    justify-self: flex-end;
    text-align: right;
}
